export default {
  setAll(state, data) {
    state.all = data.data
  },
  setActiveAll(state, data) {
    state.active = data
  },
  setView(state, data) {
    state.view = data.data
  },
  setUserAll(state, data) {
    state.users = data.data
  },
  setAllClients(state, data) {
    state.allClients = data.data
  },

  
}