export default function authHeaderFile() {
    let token = $cookies.get('token')
    if (token) {
        return {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
        };
    } else {
        return {};
    }
}