import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {

    namespaced: true,
    state() {
        return {
            all: [],
            allProducts: []
        };
    },

    actions: {

        addProduct({ commit }, product) {

            return new Promise((resolve, reject) => {
                axios
                    .post("/create-product", product, { headers: authHeader() })
                    .then(response => { resolve(response.data) })
                    .catch(error => (reject(error)));
            })
        },
        getProducts({ commit }, filter) {

            axios
                .post("/products?page=" + filter.page, filter, { headers: authHeader() })
                .then(response => (commit('setAll', response.data)))

        },
        getAllProducts({ commit }) {

            axios
                .get("/products-list-active", { headers: authHeader() })
                .then(response => (commit('setAllProducts', response.data)))

        },
        updateProduct({ commit }, product) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/update-product/" + product.product_id, product, { headers: authHeader() })
                    .then(response => { resolve(response.data) })
                    .catch(error => (reject(error)));
            })
        },
        deleteProduct({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/product-delete/" + id,
                        { headers: authHeader() })
                    .then(response => { resolve(response.data) })
                    .catch(error => (reject(error)));
            })
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data.data
        },
        setAllProducts(state, data) {
            state.allProducts = data.data
        }
    }
}