import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth/auth.module';
import user from './modules/user/index';
import client from './modules/client/index';
import productGroup from "./modules/productGroup";
import informationType from "./modules/informationType";
import product from "./modules/product/product-module"
import role from "./modules/role/index"
import media from "./modules/media/media-module"
import region from "./modules/region/region-module"
import help from "./modules/help/help-module"
import clientSiteTour from "./modules/clientSiteTour/client-tour-module"
import appNotification from "./modules/appNotification/app-notification-module"
import banner from "./modules/banner/banner-module"
import dictionary from "./modules/dictionary/dictionary-module"
import marketProfile from "./modules/marketProfile/market-profiles"
import report from "./modules/report/power-bi-report"

const store = createStore({
    modules: {
        auth,
        user,
        client,
        productGroup,
        informationType,
        product,
        role,
        media,
        region,
        help,
        clientSiteTour,
        appNotification,
        banner,
        dictionary,
        marketProfile,
        report
    }
})

export default store;