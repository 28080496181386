import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import Alert from "@/components/popup/Alert.vue";
import mitt from "mitt";
const emitter = mitt();
import router from "./router";
import vSelect from "vue-select";
import Toaster from "@meforma/vue-toaster";
import store from "./store/store";
import VueI18n from "@/plugins/i18n";
import CKEditor  from "@ckeditor/ckeditor5-vue";
import VueGtag from "vue-gtag";
import RadialProgress from "vue3-radial-progress";
import VueCookies from 'vue-cookies';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/main.css";
import "vue-select/dist/vue-select.css";
import "./assets/js/bootstrap.min.js";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

//const pinia = createPinia()

const app = createApp(App);

app.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GA4_MEASUREMENT_ID,
  },
}, router);

app.config.globalProperties.emitter = emitter;
app.component("v-select", vSelect);
app.component("alert", Alert);
app.config.globalProperties.alert = Alert.methods; // ADDED NEW !!
app.use(router);
app.use(store);
app.use(Toaster);
app.use(VueI18n);
app.use(CKEditor)
app.use(RadialProgress);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY})
app.use(VueAwesomePaginate)
app.use(VueCookies.config('default', {
  sameSite: 'None',
  secure: true
}));

app.mount("#app");

