import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
  getClients({ commit }, payload) {
    axios
      .post('/clients/list', payload, { headers: authHeader() })
      .then((response) => commit('setAllClients', response.data));
  },
  getActiveClients({ commit }) {
    axios
      .get('/active-clients', { headers: authHeader() })
      .then((response) => commit('setActiveAll', response.data));
  },
  addClient({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/clients', payload, { headers: authHeader() })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  updateClient({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put('/clients/' + payload.id, payload, { headers: authHeader() })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  deleteClient({ commit }, id) {
    return new Promise((resolve, reject) => {
        axios
            .delete("/clients/" + id,
                { headers: authHeader() })
            .then(response => { resolve(response.data) })
            .catch(error => (reject(error)));
    })
},

  viewClient({ commit }, params) {
    axios
      .get('/clients/' + params.id, { headers: authHeader() })
      .then((response) => commit('setView', response.data));
  },

  getClientLists({ commit }, payload) {
    axios
      .post('/clients/list', payload, { headers: authHeader() })
      .then((response) => commit('setAll', response.data));
  },

  getClientUsers({ commit }, id) {
    axios
      .get('/clients/' + id + '/users', { headers: authHeader() })
      .then((response) => commit('setUserAll', response.data));
  },

  inviteContact({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/user/register/' + payload.id, payload, {
          headers: authHeader(),
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  assignedProducts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/assign-products/' + payload.id, payload, {
          headers: authHeader(),
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
