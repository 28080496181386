   


import axios from 'axios';
import authHeader from '@/services/auth-header';

class EmailHelp {

    sendEmailHelp(email) {

        return new Promise((resolve, reject) => {
            axios
                .post("/help", email, { headers: authHeader() })
                .then(response => { resolve(response.data) })
                .catch(error => (reject(error)));
        })
    }
    sendContactHelp(email) {

        return new Promise((resolve, reject) => {
            axios
                .post("/contact", email, { headers: authHeader() })
                .then(response => { resolve(response.data) })
                .catch(error => (reject(error)));
        })
    }
}


export default new EmailHelp();