import axios from 'axios';
import authHeader from '@/services/auth-header';
import authHeaderFile from '@/services/auth-header-file';

export default {
  namespaced: true,
  state() {
    return {
      all: [],
      view: [],
      downloads: [],
      setFilterData: {},
      isFailure:false
    };
  },

  actions: {
    addProductInstance({ commit }, productInstance) {
      let formData = new FormData();

      for (var i = 0; i < productInstance.instances.length; i++) {
        formData.append('files[]', productInstance.instances[i].file);
        formData.append(
          'instances[' + i + '][region]',
          productInstance.instances[i].region
        );
        formData.append(
          'instances[' + i + '][language]',
          productInstance.instances[i].language
        );
      }

      formData.append('product_id', productInstance.product_id);
      formData.append('release', productInstance.release);
      formData.append('revision', productInstance.revision);
      formData.append('period_covered_by', productInstance.period_covered_by);
      formData.append(
        'period_covered_week',
        productInstance.period_covered_week
      );
      formData.append(
        'period_covered_month',
        productInstance.period_covered_month
      );
      formData.append(
        'period_covered_year',
        productInstance.period_covered_year
      );
      formData.append(
        'availability_start_datetime',
        productInstance.availability_start_datetime
      );
      formData.append(
        'availability_end_datetime',
        productInstance.availability_end_datetime
      );
      formData.append('topic', productInstance.topic);
      formData.append(
        'revision_information',
        productInstance.revision_information
      );
      formData.append('notify_subscribers', productInstance.notify_subscribers);
      formData.append('is_published', productInstance.is_published);
      if (productInstance.period_covered_start_date)
        formData.append(
          'period_covered_start_date',
          productInstance.period_covered_start_date
        );
      if (productInstance.period_covered_end_date)
        formData.append(
          'period_covered_end_date',
          productInstance.period_covered_end_date
        );
      return new Promise((resolve, reject) => {
        axios
          .post('/create-product-instance', formData, {
            headers: authHeaderFile(),
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getProductInstances({ commit }, filters) {
      axios
        .post('/product-instances', filters, { headers: authHeader() })
        .then((response) => commit('setAll', response.data));
    },
    getProductInstancesforDashboard({ commit }, filters) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-instances', filters, { headers: authHeader() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      })
    },
    getProductInstance({ commit }, params) {
      axios
        .get('/product-instance/' + params.id, { headers: authHeader() })
        .then((response) => commit('setView', response.data))
        .catch((error) => {
          if(error.response.status==404){
            commit('setFailure', true)
          }
        });
    },
    updateProductInstance({ commit }, productInstance) {
      let formData = new FormData();

      formData.append('product_id', productInstance.product_id);
      formData.append('release', productInstance.release);
      formData.append('revision', productInstance.revision);
      formData.append('period_covered_by', productInstance.period_covered_by);
      formData.append(
        'period_covered_week',
        productInstance.period_covered_week
      );
      formData.append(
        'period_covered_month',
        productInstance.period_covered_month
      );
      formData.append(
        'period_covered_year',
        productInstance.period_covered_year
      );
      formData.append(
        'availability_start_datetime',
        productInstance.availability_start_datetime
      );
      formData.append(
        'availability_end_datetime',
        productInstance.availability_end_datetime
      );
      formData.append('topic', productInstance.topic);
      formData.append(
        'revision_information',
        productInstance.revision_information
      );
      formData.append('notify_subscribers', productInstance.notify_subscribers);
      formData.append('is_published', productInstance.is_published);
      if (productInstance.period_covered_start_date)
        formData.append(
          'period_covered_start_date',
          productInstance.period_covered_start_date
        );
      if (productInstance.period_covered_end_date)
        formData.append(
          'period_covered_end_date',
          productInstance.period_covered_end_date
        );
      if (productInstance.file == null) {
      } else {
        formData.append('file', productInstance.file);
      }

      formData.append('instances[region]', productInstance.region);
      formData.append('instances[language]', productInstance.language);

      return new Promise((resolve, reject) => {
        axios
          .post('/edit-product-instance/' + productInstance.id, formData, {
            headers: authHeaderFile(),
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    deleteProductInstance({ commit }, ids) {
      return new Promise((resolve, reject) => {
        axios
        .post('/product-instance-delete', ids, { headers: authHeader() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
      });
    },
    getTopDownloads({ commit }) {
      axios
        .get('/top-downloads', { headers: authHeader() })
        .then((response) => commit('setTopDownloads', response.data));
    },
    sortOrder({ commit }, filters) {
      return new Promise((resolve, reject) => {
        axios.post('/product-instances', filters, { headers: authHeader() })
          .then((response) => {
            commit('setAll', response.data);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    saveFilter({commit}, filters) {
      return new Promise((resolve, reject) => {
        axios.post('/user-filter', filters, {headers: authHeader()})
        .then((response) => {
          commit('setAll', response.data)
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
    },
    getFilterData({commit}){
      return new Promise((resolve, reject) => {
        axios.get('/user-filter', {headers: authHeader()})
        .then((response) => {
          commit('setFilterData', response.data)
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
    },
    clearFilterData({commit}, filters) {
      console.log(filters);
      console.log(filters);
      return new Promise((resolve, reject) => {
        axios.post('/user-filter', filters,  {headers: authHeader()} )
        .then((response) => {
          commit('setAll', response.data)
          resolve(response.data)
        })
        .catch((error) => reject(error))
      })
    }
  },
  mutations: {
    setAll(state, data) {
      state.all = data.data;
    },
    setFilterData(state, data){
      state.filterData = data.data
    },
    setTopDownloads(state, data) {
      state.downloads = data;
    },
    setView(state, data) {
      state.view = data.data;
    },
    setFailure(state, data){
      state.isFailure=data
    }
  },
};
