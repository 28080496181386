import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    addUser({ commit }, payload) {

        return new Promise((resolve, reject) => {
            axios
                .post("/users", payload, { headers: authHeader() })
                .then(response => { resolve(response) })
                .catch(error => (reject(error)));
        })

    },
    createUser({ commit }, payload) {

        return new Promise((resolve, reject) => {
            axios
                .post("/auth/register/" + payload.code, payload.user, {headers:{ 'lang-code': localStorage.getItem('lang')}})
                .then(response => { resolve(response) })
                .catch(error => (reject(error)));
        })

    },
    updateUser({ commit }, payload) {

        return new Promise((resolve, reject) => {
            axios
                .put("/users/" + payload.id, payload, { headers: authHeader() })
                .then(response => { resolve(response) })
                .catch(error => (reject(error)));
        })

    },
    deleteUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/user/" + id,
                    { headers: authHeader() })
                .then(response => { resolve(response.data) })
                .catch(error => (reject(error)));
        })
    },
    updateMyAccount({ commit }, payload) {

        return new Promise((resolve, reject) => {
            axios
                .put("/my-account", payload, { headers: authHeader() })
                .then(response => { resolve(response) })
                .catch(error => (reject(error)));
        })

    },

    viewData({ commit }, params) {
        axios
            .get("/users/" + params.id, { headers: authHeader() })
            .then(response => (commit('setView', response.data)));

    },
    viewProfile({ commit }) {
        axios
            .get("/my-account", { headers: authHeader() })
            .then(response => {
                commit('setProfile', response.data)}
            );
            

    },
    getUserLists({ commit }, payload) {
        axios
            .post("/users/list", payload, { headers: authHeader() })
            .then(response => (commit('setData', response.data)));
    },
    getPendingUsers({ commit },payload) {
        axios
            .post("/pending-users", payload, { headers: authHeader() })
            .then(response => (commit('setPendingUsers', response.data)));
    },

    updatePendingStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/pending-users", payload, { headers: authHeader() })
                .then(response => { resolve(response) })
                .catch(error => (reject(error)));
        })
    },
    updateBulkStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/pending-users", payload, { headers: authHeader() })
                .then(response => { resolve(response) })
                .catch(error => (reject(error)));
        })

    },


};