export default {
    setView (state, data) {
        state.view = data.data
      },
      setProfile (state, data) {
        state.profile = data.data,
        state.user = data.data,
        localStorage.setItem('user', JSON.stringify(data))
      },
      setData(state, data) {
        state.all = data.data
      },
      setPendingUsers(state, data) {
        state.pendingUsers = data.data
      },
     
      
};