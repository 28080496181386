import axios from "axios";
import authHeader from "@/services/auth-header";

export default {
  namespaced: true,
  state() {
    return {
      all: [],
    };
  },

  actions: {
    searchDictionary({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .post("/dictionary/list", search, { headers: authHeader() })
          .then(response => { resolve(response) })
                .catch(error => (reject(error)));
      });
    },
  },
  mutations: {
    setAll(state, data) {
      state.all = data.data;
    },
  },
};
