import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
    getProductGroup({ commit }) {
        axios
            .get("/product-group", { headers: authHeader() })
            .then(response => (commit('setAll', response.data)));
    },
    getProductGroupWithProduct({ commit }, payload) {
        axios
            .get("/group-products/" + payload, { headers: authHeader() })
            .then(response => (commit('setGroupProduct', response.data)));
    }
};