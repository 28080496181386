import mutations from './mutations';
import getters from './getters';
import actions from './actions';
const user = JSON.parse(localStorage.getItem('user'));

const user_data = user
? user.data
: null;

export default {
    namespaced: true, 
    state() {
        return {
            all:[],
            view:[],
            profile:[],
            pendingUsers:[],
            user: user_data,
            
        };
    },
    mutations,
    getters,
    actions
};