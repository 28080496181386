import axios from 'axios';
import AuthService from '../../../services/auth.service';
import getters from './getters';
import authHeader from '@/services/auth-header';

const cookies = document.cookie.split('; ');
const myCookie = cookies.find(cookie => cookie.startsWith('token'));
const myCookieValue = myCookie ? myCookie.split('=')[1] : null;

const loggedIn = myCookieValue
  ? true
  : false;

export default {
  namespaced: true,
  state() {
    return {
      loggedIn: loggedIn,
      all: []
    };
  },
  getters,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          axios
          .get("/my-account", { headers: authHeader() })
          .then(response => {
            commit('setProfile', response.data)}
          );
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    
    getSignoutContent({ commit }) {
      axios
        .get('/signout-notifications')
        .then((response) => commit('setAll', response.data));
    },

    validateReportPermission({ commit }) {
      axios
        .post('/client-entitled',{ headers: authHeader() } )
        .then((response) => commit('setAll', response.data));
    },
    
    async getReportNavigation({ commit }) {
      try {
        const response = await axios.get('/tools-menu', { headers: authHeader() });
        commit('setNavigation', response.data);
      } catch (error) {
        console.error('Error fetching navigation data:', error);
      }
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user.data.user;
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.user = null;
    },
    setAll(state, data) {
      state.all = data.data;
    },
    setNavigation(state, data) {
      localStorage.setItem('toolData', JSON.stringify(data.data)); 
    },
    setProfile (state, data) {

      localStorage.setItem('user', JSON.stringify(data))
      if(data?.data?.role === 'Super Admin' || data?.data?.role === ' Data Admin' || data?.data?.role === 'Account Admin'){
        localStorage.setItem('lang', 'en')
      }
    },

  }
};  