<template>
  <div class="app-body">
    <!-- Modal Success -->
    <div class="mod-nots-1 confirmModal-1" id="successModal">
      <vue-final-modal v-model="showSuccessModal" :click-to-close="false">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="successModalLabel">Success!</h4>
            </div>
            <div class="modal-body px-4 py-4">
              <div class="mod-contnt-cstm-1 px-1">
                <p>{{ message }}</p>
              </div>
              <button type="button" class="btn mb-1 me-2" @click="showSuccessModal = false">Ok</button>
            </div>
          </div>
        </div>
      </vue-final-modal>
    </div>

    <!-- Modal Error -->

    <div class="mod-nots-1 errorModal-1" id="errorModal"
      aria-hidden="true">
      <vue-final-modal v-model="showErrorModal" :click-to-close="false">

        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="errorModalLabel">Error!</h4>
            </div>
            <div class="modal-body px-4 py-4">
              <div class="mod-contnt-cstm-1 px-1">
                <p>{{ message }}</p>
              </div>
              <button type="button" class="btn mb-1" @click="showErrorModal = false">Ok</button>
            </div>
          </div>
        </div>
      </vue-final-modal>
    </div>

    <!-- Modal Confirm -->

    <div class="mod-nots-1 successModal-1" id="confirmModal" 
      aria-hidden="true">
      <vue-final-modal v-model="showConfirmModal" :click-to-close="false">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="confirmModalLabel">Confirm!</h4>
            </div>

            <div class="modal-body px-4 py-4">
              <div class="mod-contnt-cstm-1 px-1">
                <p>{{ message }}</p>
              </div>
            </div>
            <div class="modal-footer d-block">
              <button type="button" class="btn mb-1" @click="showConfirmModal = false">Close</button>
              <button type="button" class="btn btn-info" @click="showConfirmModal = !showConfirmModal">Yes</button>
            </div>
          </div>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
import { $vfm, VueFinalModal, ModalsContainer } from "vue-final-modal";

export default {
  name: "SuccessAlert",
  components: { VueFinalModal, ModalsContainer },
  data() {
    return {
      message: "",
      title: "",
      showSuccessModal: false,
      showErrorModal: false,
      showConfirmModal: false,
    };
  },

  mounted() {
    this.emitter.on("success-message", (message) => {
      this.showSuccessModal = true;
      this.message = message;
    });
    this.emitter.on("error-message", (message) => {
      this.showErrorModal = true;
      this.message = message;
    });
    this.emitter.on("confirm-message", (message) => {
      this.showConfirmModal = true;
      this.message = message;
    });
  },
};
</script>
