import sendEmailHelp from "@/services/help-service";

export default {

    namespaced: true,
    state() {
        return {
            all: []
        };
    },

    actions: {
       
        emailHelp({ commit }, email) {
            return sendEmailHelp.sendEmailHelp(email);
        }, 
        contactHelp({ commit }, contact) {
            return sendEmailHelp.sendContactHelp(contact);
        }, 
    },
    mutations: {
        setAll(state, data) {
            state.all = data
        }
    }
}