import axios from 'axios';
import authHeader from '@/services/auth-header';
import authHeaderFile from '@/services/auth-header-file';

export default {
  namespaced: true,
  state() {
    return {
      all: [],
      view: [],
    };
  },

  actions: {
    addBanner({ commit }, { bannerData, banner_file, product_group_id }) {
      let formData = new FormData();
      formData.append('banner_file', banner_file);
      formData.append('english_content', bannerData.english_content);
      formData.append('french_content', bannerData.french_content);
      formData.append('en_cta', bannerData.en_cta);
      formData.append('en_link', bannerData.en_link);
      formData.append('fr_cta', bannerData.fr_cta);
      formData.append('fr_link', bannerData.fr_link);
      formData.append('status', bannerData.status);
      formData.append('product_group_id', product_group_id);
      formData.append('publish_end_datetime', bannerData.publish_end_datetime);
      formData.append('publish_start_datetime', bannerData.publish_start_datetime);
      
      return new Promise((resolve, reject) => {
        axios
          .post('/banners', formData, { headers: authHeaderFile() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    updateViewContent({ commit }, notification) {
      return new Promise((resolve, reject) => {
        axios
          .put('/banners', notification, { headers: authHeader() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getBanners({ commit }) {
      axios
        .get('/banners', { headers: authHeader() })
        .then((response) => commit('setAll', response.data));
    },
    getBannersDisplay({ commit }) {
      axios
        .get('/banners/display', { headers: authHeader() })
        .then((response) => commit('setAll', response.data));
    },
    getSingleBanner({ commit }, params) {
      axios
        .get('/banners/' + params.id, { headers: authHeader() })
        .then((response) => commit('setView', response.data));
    },
    updateSingleBanner({ commit }, { bannerData, banner_file, product_group_id, params }) {
      let formData = new FormData();
      if (banner_file == undefined) {
        formData.append('banner_file', {});
      } else {
        formData.append('banner_file', banner_file);
      }

      formData.append('english_content', bannerData.english_content);
      formData.append('french_content', bannerData.french_content);
      formData.append('en_cta', bannerData.en_cta);
      formData.append('en_link', bannerData.en_link);
      formData.append('fr_cta', bannerData.fr_cta);
      formData.append('fr_link', bannerData.fr_link);
      formData.append('status', bannerData.status);
      formData.append('product_group_id', product_group_id);
      formData.append('publish_end_datetime', bannerData.publish_end_datetime);
      formData.append('publish_start_datetime', bannerData.publish_start_datetime);
      return new Promise((resolve, reject) => {
        axios
          .post('/banners/' + bannerData.id, formData, {
            headers: authHeaderFile(),
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },

    deleteBanner({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/banners/' + id, { headers: authHeader() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
  mutations: {
    setAll(state, data) {
      state.all = data.data;
    },
    setView(state, data) {
      state.view = data.data;
    },
  },
};