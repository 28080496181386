import axios from 'axios';
import authHeader from '@/services/auth-header';
import authHeaderFile from '@/services/auth-header-file';

export default {

    namespaced: true,
    state() {
        return {
            all: []
        };
    },

    actions: {

        addTourFiles({ commit }, {frenchFile, englishFile}) {
            let formData = new FormData();
            formData.append('englishFile', englishFile);
			formData.append('frenchFile', frenchFile);

            return new Promise((resolve, reject) => {
                axios
                    .post("/video-tours", formData, { headers: authHeaderFile() })
                    .then(response => { resolve(response.data) })
                    .catch(error => (reject(error)));
            })
        },
        getTourFiles({ commit }) {
            axios
                .get("/video-tours", { headers: authHeader() })
                .then(response => (commit('setAll', response.data)));
        },
        
        deleteTourFile({ commit }, id) {

            return new Promise((resolve, reject) => {
                axios
                    .delete("/video-tours-delete/" + id,
                        { headers: authHeader() })
                    .then(response => { resolve(response.data) })
                    .catch(error => (reject(error)));
            })
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data.data
        }
    }
}