import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
const messages = {
    'en':en,
    'fr':fr
};
export default createI18n({
    legacy: false,
    locale: 'en',
     fallbackLocale: 'en',
    messages: messages,
});