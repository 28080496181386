import axios from 'axios';
import authHeader from '@/services/auth-header';

class AuthService {
  login(user) {
    return axios
      .post("/auth/login", {
        email: user.email,
        password: user.password
      }, {headers: {'lang-code': localStorage.getItem('lang')}})
      .then(response => {
        if (response.data.status) {
          $cookies.set('token', response.data.data.token,'','/');
        }
        return response.data;
      });
  }

}

export default new AuthService();