import axios from "axios";

export default {
  namespaced: true,
  state: {
    hasRequestedVAM: localStorage.getItem('hasRequestedVAM') === 'true',
    hasRequestedETAM: localStorage.getItem('hasRequestedETAM') === 'true'
  },
  
  actions: {
    requestReport({ commit, state }, { reportType, lang, slug }) {
      let token = $cookies.get('token');
      if (reportType === 'vam' && !state.hasRequestedVAM) {
        commit('setHasRequestedVAM', true);
        
        return axios.get(`/${lang}/${reportType}/pbi-dashboard/${slug}`, {
          params: { vamStatus: state.hasRequestedVAM },
          headers: { Authorization: 'Bearer ' + token},
        });
      } else if (reportType === 'etam' && !state.hasRequestedETAM) {
        commit('setHasRequestedETAM', true);
        
        return axios.get(`/${lang}/${reportType}/pbi-dashboard/${slug}`, {
          params: { etamStatus: state.hasRequestedETAM },
          headers: { Authorization: 'Bearer ' + token},
        });
      } else {
        return axios.get(`/${lang}/${reportType}/pbi-dashboard/${slug}`, {
          headers: { Authorization: 'Bearer ' + token},
        });
      }
    }
  },
  
  mutations: {
    setHasRequestedVAM(state, value) {
      state.hasRequestedVAM = value;
      localStorage.setItem('hasRequestedVAM', value);
    },
    setHasRequestedETAM(state, value) {
      state.hasRequestedETAM = value;
      localStorage.setItem('hasRequestedETAM', value);
    }
  },

};
