import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {

    namespaced: true,
    state() {
        return {
            all: []
        };
    },
    getters:[],
    actions: {
        getRoles({ commit }) {
            axios
                .get("/roles", { headers: authHeader() })
                .then(response => (commit('setAll', response.data)));
        },
    },
    mutations: {
        setAll(state, data) {
            state.all = data.data
        }
    }
}