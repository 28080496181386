import axios from "axios";
import authHeader from "@/services/auth-header";
import authHeaderFile from "@/services/auth-header-file";

export default {
  namespaced: true,
  state() {
    return {
      all: [],
      view: [],
    };
  },

  actions: {
    addNotifications({ commit }, editorData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/app-notification", editorData, { headers: authHeaderFile() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    updateViewContent({ commit }, notification) {
      return new Promise((resolve, reject) => {
        axios
          .put("/app-notification", notification, { headers: authHeader() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getNotifications({ commit }) {
      axios
        .get("/app-notification", { headers: authHeader() })
        .then((response) => commit("setAll", response.data));
    },
    getNotificationsDisplay({ commit }) {
      axios
        .get("/app-notification/display", { headers: authHeader() })
        .then((response) => commit("setAll", response.data));
    },
    getSingleNotification({ commit }, params) {
      axios
        .get("/app-notification/" + params.id, { headers: authHeader() })
        .then((response) => commit("setView", response.data));
    },
    updateSingleNotification({ commit }, editorData) {
      return new Promise((resolve, reject) => {
        axios
          .put("/app-notification/" + editorData.id, editorData, {
            headers: authHeader(),
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    
    getSingnOutNotification({ commit }) {
      axios
        .get("/signout-notifications/show",{ headers: authHeader() })
        .then((response) => commit("setView", response.data));
    },
    addSignOutNotification({ commit }, signOutNotification) {
      return new Promise((resolve, reject) => {
        axios
          .post("/signout-notifications", signOutNotification, { headers: authHeaderFile() })
          .then((response) => {
            resolve(response.data);
            console.log(response.data)
          })
          .catch((error) => reject(error));
      });
    },

    deleteNotification({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/app-notification/" + id, { headers: authHeader() })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
  mutations: {
    setAll(state, data) {
      state.all = data.data;
    },
    setView(state, data) {
      state.view = data.data;
    },
  },
};
