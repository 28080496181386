<template>
  <div id="app">
  </div>
  <div>
    <router-view></router-view>
  </div>
  <loading :active.sync="isLoading" is-full-page></loading>
  <alert />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';


export default {
  name: 'app',
  components: {
    Loading,
  },
  mounted() {
    this.enableInterceptor()
  },
  created() {
    this.trackPageView();
  },

  data() {
    return {
      isLoading: false,
      refCount: 0,
      axiosInterceptor: null,
      day: new Date().getDate(),
      month: new Date().toLocaleString('default', { month: 'long' }),
      year: new Date().getFullYear(),
    }
  },
  methods: {
    trackPageView() {
      const user = this.$store.state.user;
      const pagePath = this.$route.path;

      gtag('event','Page Track', (pagePath, {
        user_id: user.user_id,
        user_name: user.user_name,
        user_role: user.user_role,
      }))
    },
    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++
        this.isLoading = true
      } else if (this.refCount > 0) {
        this.refCount--
        this.isLoading = (this.refCount > 0)
      }
    },
    
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {
        this.setLoading(true)
        return config
      }, (error) => {
        this.setLoading(false)
        return Promise.reject(error)
      })
      axios.interceptors.response.use((response) => {
        this.setLoading(false)
        this.trackApiRequestSuccess(response.config.url);
        return response
      }, (error) => {
        this.setLoading(false)
        this.trackApiRequestError(error.config.url);
        if (error?.response?.status === 401) { // status code for expired token
          localStorage.removeItem('user')
          localStorage.removeItem('activityTime')
          localStorage.removeItem('userView')
          localStorage.removeItem('toolData') 
          this.$store.commit('vam/setHasRequestedVAM', false);
          this.$store.commit('etam/hasRequestedETAM', false);
          localStorage.removeItem('hasRequestedETAM');
          document.cookie = 'token=; max-age=0; path=/';
          location.reload()
        }
        return Promise.reject(error)
      })
    },
    trackApiRequestSuccess(url) {
      gtag('event', 'API Request', {
        'api_requests_event_category': 'API',
        'api_requests_event_label': 'Success',
        'api_requests_event_value': url,
        'api_requests_date': `${this.day} ${this.month} ${this.year}`
      });
    },
    trackApiRequestError(url) {
      gtag('event', 'API Request', {
        'api_requests_event_category': 'API',
        'api_requests_event_label': 'Error',
        'api_requests_event_value': url,
        'api_requests_date': `${this.day} ${this.month} ${this.year}`
      });
    },

    disableInterceptor() {
      axios.interceptors.request.eject(this.axiosInterceptor)
    },

  },
}
</script>